import Vue   from 'vue';

import ArticleElement from './components/ArticleElement.vue';

export default class ArticleElements {

    /**
     * Create a new instance.
     *
     */
    constructor(url) {

      this.components = [];
        this.componentCounter = 0;
        this.componentsToLoad = 0;

      this.url = url || window.location.origin + '/article';
      this.selector = '#newsletterContent *[data-updatetype="article"] .component';
        Vue.component('article-element', ArticleElement);


    }

    init() {

        App.event.listen('article.rendered', () => {
            this.componentCounter++;
            if(this.componentCounter === this.componentsToLoad) {
                // Wait for a few MS, because the article dom needs some time to render
                // and the events seem to be firing too early
                setTimeout(() => {
                    populateSidebarNavigation();
                    setDefinitionClickEvents();
                }, 150);
            }

        });
      document.querySelectorAll(this.selector).forEach((element) => {
        this.components.push(
          new Vue({url: this.url, el: element})
        );
        this.componentsToLoad++;
      });

    }

  }
