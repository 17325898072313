import Promise from 'promise';
import swal from 'sweetalert2'

export default class PublishArticle {

    /**
     * Hooks into anchor tags with data-role="message-author"
     * and opens an alert with an option to send a message to author.
     *
     */
    constructor() {
      $(() => {
        this.init();
      });
    }

    init() {
      $('a[data-role="publish-article"]').on('click', (e) => this.openModal(e));
    }

    openModal(e) {
      e && e.preventDefault();

      const article = $(e.currentTarget).data('article');

      swal({
        html: 'Wollen Sie den Artikel "<i>' + document.title + '</i>" veröffentlichen?',
        showCancelButton: true,
        confirmButtonText: 'Veröffentlichen',
        cancelButtonText: 'Abbrechen',
        showLoaderOnConfirm: true,
        allowOutsideClick: true
      }).then((resp) => {
        if (resp) {
          new App.xhr({article})
            .post(window.location.origin + '/article/publish')
            .then((resp) => {
              if (resp.success) {
                swal({type: 'success', title: 'Artikel veröffentlicht!'})
              } else {
                swal({type: 'error', title: 'Artikel nicht veröffentlicht.'})
              }
            })
            .catch(err => {
              swal({type: 'error', title: 'Artikel nicht veröffentlicht.'})
            });
        }
      }).catch(err => {});
    }

  }
