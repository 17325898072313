<template>
    <div class="row filters">
        <div class="col-md-3">
            <select2 v-model="country" :options="countryOptions" placeholder="-- Land wählen --" :multiple="true"></select2>
        </div>
        <div class="col-md-3">
            <select2 v-model="type" :options="typeOptions" placeholder="-- MM-Typ wählen --" :multiple="true"></select2>
        </div>
        <div class="col-md-2">
            <select2 v-model="numItems" :options="numOptions"></select2>
        </div>
        <div class="col-md-3">
            <input class="filter" type="text" placeholder="Tabelle filtern..." v-model="filter" @change="change"/>
        </div>
        <div class="col-md-2">
            <i v-if="loading" class="fa fa-spinner fa-spin"></i>
        </div>
    </div>
</template>

<script>

import Select2 from '../Select2.vue';
import throttle from 'lodash/throttle';

export default {

    components: {
        Select2
    },

    props: {

        countries: {
            default: []
        },
        types: {
            default: []
        },
        loading: {
            default: false
        }

    },

    data() {
        return {
            type: [],
            filter: '',
            country: [],
            numItems: 10,
            setData: 0,
        }
    },

    computed: {

        typeOptions() {
            return this.types.map((type) => {
                return {
                    id: type,
                    text: type
                }
            });
        },

        countryOptions() {
            return this.countries.map((country) => {
                return {
                    id: country,
                    text: country
                }
            });
        },

        numOptions() {
            return [
                {id: 10, text: '10'},
                {id: 25, text: '25'},
                {id: 50, text: '50'},
                {id: 100, text: '100'},
            ]
        },

    },

    methods: {

        change: throttle(function () {
            this.$emit('change', this.values());
        }, 100),

        values() {
            return {
                type: this.type,
                filter: this.filter,
                country: this.country,
                numItems: this.numItems
            }
        }

    },

    watch: {
        type() { this.setData === 2 ? this.change() : this.setData++; },
        country() { this.setData === 2 ? this.change() : this.setData++; },
        numItems() { this.setData === 2 ? this.change() : this.setData++; },
    }
}
</script>
