<template>
    <div class="map-container">
        <gmap-map ref="map" :center="center" :zoom="zoom" @zoom_changed="updateZoom">
            <template v-if="data.length">
                <gmap-cluster>
                    <gmap-marker v-for="marker in data" :key="marker.id" :position="getPosition(marker)" :clickable="true" @click="openLocation(marker.id)"></gmap-marker>
                </gmap-cluster>
            </template>
            <template v-if="info">
                <gmap-info-window :position="getPosition(info, true)" @closeclick="info = null">
                    <b>{{ info.firm }}</b>
                    <br>
                    {{ info.street }}, {{ info.city }} {{ info.postalCode }}, {{ info.country }}
                    <br><br>
                    <em><b>MM-Typ:</b> {{ info.MMTypValue }} </em><br>
                    <em><b>Verkauf Fläche:</b> {{ info.salesArea }}m<sup>2</sup> </em><br>
                    <em><b>Kundenanzahl:</b> {{info.Kundenanzahl }} </em><br>
                    <em><b>Umsatz netto:</b> {{ info['Umsatz netto'] }} </em><br>
                    <em><b>Durchschnittsumsatz:</b> {{info.Durchschnittsbon }} </em>
                </gmap-info-window>
            </template>
        </gmap-map>
    </div>
</template>

<script>
export default {

    props: {
        data: {
            default: []
        }
    },

    data () {
        return {
            zoom: 7,
            info: null,
            center: {
                lat: 46.572267300000000000,
                lng: 17.409320100000060000
            }
        };
    },

    methods: {

        setCenter (location) {

            this.center = location ?
                this.getPosition(location) : (
                    this.data.length ?
                        this.getPosition(this.data[0]) :
                        {
                            lat: 46.572267300000000000,
                            lng: 17.409320100000060000
                        }
                );

        },

        getPosition (item, marker) {
            return {
                lat: parseFloat(item.latitude) + (marker ? 0.005 : 0),
                lng: parseFloat(item.longitude)
            };
        },

        updateZoom (zoom) {
            this.zoom = zoom;
        },

        openLocation (id) {
            this.zoom = 13;
            this.info = this.data.filter((item) => {
                return item.id == id;
            }).pop();

            this.setCenter(this.info);
        },

        restore () {
            this.info = null;
            this.setCenter();
            this.setZoom();
        },

        setZoom (value) {
            this.zoom = value ? value : 7;
        }

    },

    created() {
        this.setCenter();
    }
}
</script>
