import { createMarkup } from '../utils'
import Popper from 'popper.js'

export default class Tooltip {
  constructor (reference, content, container) {
    if (content) {
      this.reference = reference
      this.container = container || document.body

      const width = Math.min(this.container.el.offsetWidth, 360);

      this.el = createMarkup(`<div class="imagemap-zoom--tooltip" style="width:${width}px;">
        ${content}
      </span>`)

    }
  }

  show () {
    const width = Math.min(this.container.el.offsetWidth, 360);
    this.el.style.width = `${width}px`;

    document.body.appendChild(this.el);
    this.popper = new Popper(this.reference, this.el, {
      placement: 'right',
      modifiers: {
        flip: {
          behavior: ['right', 'left', 'bottom', 'top']
        },
        preventOverflow: {
          boundariesElement: this.container.el,
        },
      },
    })
  }

  hide () {
    this.popper.destroy();
    document.body.removeChild(this.el);
  }
}
