export default class ImageSubtitles {

    /**
     * Create a new instance.
     *
     * @param {string} article detail path
     */
    constructor(selector) {
      this.selector = selector ? selector : 'div[data-updatetype="image"] img';
      this.init()
    }

    init() {
      setTimeout(() => {
        document.querySelectorAll(this.selector).forEach((element) => {
          this.appendSubtitle(element);
        });
      }, 100);
    }

    appendSubtitle(element) {
      let title = element.title;

      if (title.length) {

        let span = document.createElement('span');
            span.innerHTML = "<small>" + title + "</small>";

        if (element.style.float === 'left') {
          element.parentNode.parentNode.style.paddingTop = '0';
          element.parentNode.parentNode.style.paddingBottom = '0';
          element.style.marginTop = '15px';
          span.style.float = 'left';
          span.style.clear = 'left';
        }

        if (element.style.float === 'right') {
          element.parentNode.parentNode.style.paddingTop = '0';
          element.parentNode.parentNode.style.paddingBottom = '0';
          element.style.marginTop = '15px';
          span.style.float = 'right';
          span.style.clear = 'right';
        }

        this.insertAfter(element, span);
      }
    }

    insertAfter(referenceNode, newNode) {
      referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
    }
  }
