<template>
    <select class="form-control">
        <slot></slot>
    </select>
</template>

<script>

export default {

    name: 'select2',

    props: ['options', 'value', 'placeholder', 'multiple', 'allowClear'],

    mounted() {

        this.init();

    },

    methods: {

        init() {

            // update options
            $(this.$el)
                .empty()
                .select2({ data: this.options, placeholder: this.placeholder, multiple: this.multiple, allowClear: this.allowClear })
                .val(this.value)
                .trigger('change')
                .on('change', ({currentTarget}) => {

                    const value = this.multiple ?
                        $(this.$el).select2('data').map(item => item.id):
                        currentTarget.value;

                    this.$emit('input', value);
                });
        }

    },

    watch: {
        value() {
            // update value
            $(this.$el).off().select2('destroy');
            this.init();
        },
        options() {
            this.init()
        }
    },

    destroyed() {
        $(this.$el).off().select2('destroy');
    }
}
</script>
