import EventEmitter from '../utils/event-emitter'

class Gestures extends EventEmitter {
  constructor (el) {
    super()
    let interaction = null
    let fingers = 0
    let prevFingers = 0
    let lastTouchStart = null
    let startTouches = null
    let isDoubleTap = false

    const setInteraction = (newInteraction, event) => {
      if (interaction !== newInteraction) {
        if (interaction && !newInteraction) {
          switch (interaction) {
            case 'zoom':
              this.dispatchEvent('zoom.end', { event })
              break
            case 'drag':
              this.dispatchEvent('drag.end', { event })
              break
          }
        }

        switch (newInteraction) {
          case 'zoom':
            this.dispatchEvent('zoom.start', { event })
            break
          case 'drag':
            this.dispatchEvent('drag.start', { event })
            break
        }
      }
      interaction = newInteraction
    }

    const updateInteraction = function (event) {
      if (fingers === 10) {
        console.warn('Oh my. A bit touchy arent we? Someone will have to clean up the display!')
      }
      if (fingers === 2) {
        setInteraction('zoom', event)
      } else if (fingers === 1 && prevFingers === 0) {
        setInteraction('drag', event)
      } else {
        setInteraction(null, event)
      }
    }

    const targetTouches = function (touches) {
      return Array.from(touches).map(function (touch) {
        return {
          x: touch.pageX,
          y: touch.pageY
        }
      })
    }

    const getDistance = function (a, b) {
      var x, y
      x = a.x - b.x
      y = a.y - b.y
      return Math.sqrt(x * x + y * y)
    }

    const calculateScale = function (startTouches, endTouches) {
      const startDistance = getDistance(startTouches[0], startTouches[1])
      const endDistance = getDistance(endTouches[0], endTouches[1])

      return endDistance / startDistance
    }

    const cancelEvent = function (event) {
      if (event.cancelable) {
        event.stopPropagation()
        event.preventDefault()
      }
    }

    const detectDoubleTap = (event) => {
      var time = (new Date()).getTime()

      if (fingers > 1) {
        lastTouchStart = null
      }

      if (time - lastTouchStart < 300) {
        cancelEvent(event)

        isDoubleTap = true
        this.dispatchEvent('doubletap', { event })
        switch (interaction) {
          case 'zoom':
            this.dispatchEvent('zoom.end', { event })
            break
          case 'drag':
            this.dispatchEvent('drag.end', { event })
            break
        }
      } else {
        isDoubleTap = false
      }

      if (fingers === 1) {
        lastTouchStart = time
      }
    }

    let firstMove = true

    el.addEventListener('touchstart', event => {
      firstMove = true
      prevFingers = fingers
      fingers = event.touches.length
      detectDoubleTap(event)
    })

    el.addEventListener('touchmove', event => {
      if (!isDoubleTap) {
        if (firstMove) {
          updateInteraction(event)
          if (interaction) {
            cancelEvent(event)
          }
          startTouches = targetTouches(event.touches)
        } else {
          switch (interaction) {
            case 'zoom':
              if (startTouches.length === 2 && event.touches.length === 2) {
                this.dispatchEvent('zoom', {
                  event,
                  scale: calculateScale(startTouches, targetTouches(event.touches)),
                  touches: targetTouches(event.touches)
                })
              }
              break
            case 'drag':
              this.dispatchEvent('drag', { event })
              break
          }
          if (interaction) {
            // cancelEvent(event)
          }
        }

        firstMove = false
      }
    })

    el.addEventListener('touchend', (event) => {
      prevFingers = fingers
      fingers = event.touches.length

      updateInteraction(event)
    })
  }

  destroy () {
    this.removeAllListeners()
  }
}

Gestures.detect = (el) => {
  return new Gestures(el)
}

export default Gestures
