import { createMarkup, isMac } from '../utils'

export default class Overlay {
  constructor (container, type = 'scroll') {
    const { locale, messages } = container.config
    this.locale = locale
    this.container = container
    this.messages = messages[locale]
    this.timeout = null

    const message = messages[locale][type].replace(
      ':key', isMac() ? '⌘' : 'Strg'
    )

    this.el = createMarkup(
      `<div class="imagemap-zoom--overlay">${message}</div>`
    )
  }

  show () {
    if (!document.body.contains(this.el)) {
      this.clear()
      this.container.addChild(this.el)
      setTimeout(() => this.el.classList.add('imagemap-zoom--overlay-show'), 1)
      this.timeout = setTimeout(() => this.hide(), 3000)
    }
  }

  hide () {
    if (this.el.classList.contains('imagemap-zoom--overlay-show')) {
      this.clear()
      this.el.classList.remove('imagemap-zoom--overlay-show')
      setTimeout(() => this.container.removeChild(this.el), 300)
    }
  }

  clear () {
    this.timeout && clearTimeout(this.timeout)
    this.timeout = null
  }
}
