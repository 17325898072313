<template>
    <div>
        <div class="row underline">
            <div class="col-md-12">
                <location-filter :countries="countries" :types="types" ref="filter" @change="filter" :loading="loading"></location-filter>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 tabContainer">
                <div :class="['tab', showTab == 1 ? 'selected' : '']" @click="showTab = 1">Listenansicht</div>
                <div :class="['tab', showTab == 2 ? 'selected' : '']" @click="showTab = 2">Kartenansicht</div>
                <a href="#" @click.prevent="exportData" class="export" v-if="showTab == 1">
                    <i v-if="exporting" class="fa fa-fw fa-spinner fa-spin" aria-hidden="true"></i>
                    <i v-else class="fas fa-fw fa-download" aria-hidden="true"></i>
                </a>
            </div>
            <div class="col-md-12" v-show="showTab == 1">
                <contact-table @rowClick="showOnMap" :data="fetchData" :pagination="pagination" :loading="loading" ref="table"></contact-table>
            </div>
            <div class="col-md-12" v-show="showTab == 2">
                <contact-map :data="locations" ref="map"></contact-map>
            </div>
        </div>
    </div>
</template>

<script>
import Vue            from 'vue';
import ContactMap     from './ContactMap.vue'
import ContactTable   from './ContactTable.vue'
import LocationFilter from './LocationFilter.vue'

export default {

    components: {
        ContactMap,
        ContactTable,
        LocationFilter
    },

    data() {
        return {
            data: {
                data: []
            },
            types: [],
            countries: [],
            locations: [],
            pagination: null,
            showTab: 1,
            exporting: false,
            loading: false,
        }
    },

    methods: {
        async fetchData ({ page, sort, filter }) {

            this.loading = true;

            const response = await new App.xhr({ page, sort, ...this.$refs.filter.values() })
                    .post('/locations')

            this.pagination = response.pagination;
            this.data = response.data;

            if (!this.types.length) {
                this.types = response.types;
            }

            if (!this.countries.length) {
                this.countries = response.countries;
            }

            if (this.locations.length != response.locations.length) {
                this.locations = response.locations;
                this.$refs.map.restore();
            }

            this.loading = false;

            return response;
        },

        filter() {
            Vue.nextTick(() => {
                this.$refs.table.refresh();
            });
        },

        showOnMap (id) {
            this.$refs.map.openLocation(id);
        },

        exportData() {

            this.exporting = true;

            let sort = this.$refs.table.sorting();

            new App.xhr({ sort, ...this.$refs.filter.values() })
                .post('/locations/export')
                .then(({url}) => {
                    this.exporting = false;
                    window.location = url;
                });
        }

    },

    created() {

    }
}
</script>
