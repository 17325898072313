import Promise from 'promise';
import ImageSubtitles from '../modules/ImageSubtitles';

/**
 * Creates <script> element, attaches it
 * to document <body></body> and resolves
 * a promise when script is loaded.
 *
 * @param {string} url
 * @return Promise - resolves to the script element added to body.
 */
export function loadScript(url) {
  return new Promise((resolve, reject) => {

    let script = document.createElement('script');

    script.onload = () => {
      resolve(script);
    };

    script.src = url;

    document.body.appendChild(script);

  });
}

/**
 * Creates <link rel="stylesheet"> element
 * and attached it to document <head></head>
 *
 * @param {string} url
 */
export function loadStyle(url) {

  let style = document.createElement('link');
      style.rel = "stylesheet";
      style.href = url;

  document.head.appendChild(style);

}


export function initArticleScripts(id) {

  let selectorRestriction = typeof id === "undefined" ? "" : '#articleElement-' + id + ' ';
  //- Setup gallery slider
  let galleries = $(selectorRestriction + 'div[data-updatetype="image_gallery"]').each(function(index, container) {
    let gallery = $(container).find('.image-gallery-images');
    var flickityOptions = JSON.parse(atob(gallery.attr('data-flickity-options')));
    gallery.flickity(flickityOptions);
  });

  //- Setup article tables
  if (window.ArticleTable) {
    document.querySelectorAll(selectorRestriction + 'div[data-updatetype="table"] .component').forEach((element) => {
        ArticleTable.init(element);
    });
  }

  new ImageSubtitles(selectorRestriction + 'div[data-updatetype="image"] img');

  var articleElementId = $('#articleElement-' + id);

  //Toggle Collection Articles
  articleElementId.on('click', '.main-title', function(event) {
    $(this).closest('.nl_designer_row_table').siblings('.nl_designer_row_table').toggle(100);
    $(this).closest('#articleElement-' + id).find('.article-contacts').toggle(100);

    if ($(this).hasClass('expanded')) {
        $(this).removeClass('expanded');
    } else {
        $(this).addClass('expanded');
    }

    var pdf_attachment_a = articleElementId.find('div[data-updatetype="attachment"] a[href*=".pdf"]');

    if (pdf_attachment_a.find('span').length == 0) {
      pdf_attachment_a.append('<span />');
    }

  });


  //Toggle PDF Attachment
  articleElementId.on('click', 'div[data-updatetype="attachment"] a[href*=".pdf"] span', function(event) {
    event.preventDefault();

    if ($(this).hasClass('expanded')) {
        $(this).removeClass('expanded');
        $(this).closest('.col_element').siblings('.pdf_object').remove();
    } else {
        $('div[data-updatetype="attachment"]').siblings('.pdf_object').remove();
        $('div[data-updatetype="attachment"] a[href*=".pdf"] span').removeClass('expanded');

        $(this).addClass('expanded');
        var url_iframe = $(this).closest('a').attr('href')+'&inline=true';
        $(this).closest('.nl_col_content').append('<div class="col_element pdf_object"><object width="100%" height="600px" data="'+url_iframe+'" type="application/pdf"><embed src="'+url_iframe+'" type="application/pdf" /></object></div>');
    }
  });

  //Hide unpublished article
  $('.article-not-found').closest('.nl_designer_row_table').addClass('hidden');

}

/**
 * Finds closest parent of the element for the given selector.
 *
 * @param {string} s Selector string
 * @param {HtmlNode} el Element to search from
 */
export function closest(s, el) {
  if (!document.documentElement.contains(el)) return null;

  do {
    if (el.msMatchesSelector) {
      if (el.msMatchesSelector(s)) return el;
    } else {
      if (el.matches(s)) return el;
    }
    el = el.parentElement || el.parentNode;
  } while (el !== null && el.nodeType === 1);

  return null;
};

export const imageLoaded = src =>
  new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img)
    img.onerror = e => reject(e)
    img.src = src
  })

