import ImageMapApp from './app'

/**
 * Exported module - returns a
 * function that resolves to true.
 * @type {Function}
 */
class ImageMapZoom {
  constructor (el, options) {
    this.instances = []

    if (typeof el === 'string') {
      el = document.querySelectorAll(el)
    }

    if (el && el.length) {
      [].forEach.call(el, img => this.instances.push(
        new ImageMapApp(img, options)
      ))
    } else if (el && el.nodeName) {
      this.instances.push(
        new ImageMapApp(el, options)
      )
    }
  }

  destroy () {
    this.instances.map(i => i.destroy())
  }
}

export default ImageMapZoom
