<template>
    <div class="row" v-if="data.length">
        <div class="col-lg-12">
            <div class="article-contacts">
                <div class="row" v-for="articleContact in data" :key="articleContact.id">
                    <div class="col-lg-12 article-contact-header">
                        <h3 :id="'article-contact-' + articleContact.id">
                            {{ articleContact.name }}
                        </h3>
                        <hr>
                    </div>

                        <div v-for="contact in articleContact.contacts" :key="contact.id" class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 article-contact">
                            
                            <div v-for="dynamicField in contact.dynamicFields" :key="dynamicField.id">
                                <template>
                                    {{ dynamicField }}
                                    <br>
                                </template>
                            </div>

                            <template v-if="contact.contactName.length">
                                <span class='contact-name'>
                                    <a :href="'/contact/' + contact.id">{{ contact.contactName }}</a>
                                </span>
                                <br>
                            </template>

                            <template v-if="contact.street.length">
                                {{ contact.street }}
                                <br/>
                            </template>

                            <template v-if="contact.cityWithPostalCode.length">
                                {{ contact.cityWithPostalCode }}
                                <br/>
                            </template>

                        </div>
                    <hr>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  props: {
      data: {
          default: [],
          required: true
      }
  }
}
</script>

