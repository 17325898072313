class TextareaAutosize {
  constructor(options = {}) {
    this.options = {
      ...TextareaAutosize.defaultOptions,
      ...options
    };

    this.elements = typeof this.options.el === 'string'
      ? document.querySelectorAll(this.options.el)
      : [el];

    [].forEach.call(this.elements, el => {
      el.addEventListener('focus', this)
      el.addEventListener('keyup', this)
      this.resize(el);
    });
  }

  resize(element) {
    element.style.height = 'auto'
    let contentHeight = element.scrollHeight + 1
    const { minHeight, maxHeight } = this.options;

    if (minHeight) {
      contentHeight = contentHeight < minHeight
        ? minHeight
        : contentHeight
    }

    if (maxHeight) {
      contentHeight = contentHeight > maxHeight
        ? maxHeight
        : contentHeight
    }

    element.style.height = `${contentHeight}px`;
  }

  handleEvent({ type, currentTarget }) {
    switch (type) {
      case 'focus':
      case 'keyup':
        this.resize(currentTarget);
        break;
    }
  }

  destroy() {
    [].forEach.call(this.elements, el => {
      el.removeEventListener('focus', this)
      el.removeEventListener('keyup', this)
    });
  }
}

TextareaAutosize.defaultOptions = {
  el: '[data-updatetype="input-field"] textarea',
  maxHeight: null,
  minHeight: 46
}

export default TextareaAutosize;
