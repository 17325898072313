import EventEmitter from '../utils/event-emitter'
import Gestures from './gestures'
import UI from '../ui'

class TouchHandler extends EventEmitter {
  constructor (scope) {
    super()
    this.el = scope.el
    this.img = scope.img
    this.config = scope.config
    this.zoomHandler = scope.zoom
    this.initialCenter = null
    this.previousEvent = null
    this.previousScale = 1

    this.overlay = new UI.Overlay(scope, 'mobile')

    this.gestures = Gestures.detect(this.el)
    this.gestures.addEventListener('zoom', this)
    this.gestures.addEventListener('zoom.start', this)
    this.gestures.addEventListener('zoom.end', this)
    this.gestures.addEventListener('drag', this)
    this.gestures.addEventListener('drag.start', this)
    this.gestures.addEventListener('drag.end', this)
    this.gestures.addEventListener('doubletap', this)

    this.draggable = () => scope.draggable()
  }

  handleEvent (e) {
    let types = e.type.split('.')
    let fn = types.length > 1
      ? types.shift() + types[0].charAt(0).toUpperCase() + types[0].slice(1)
      : types.pop()

    this[fn](e)
  }

  getCenter (touches) {
    return {
      x: touches.map(function (v) { return v.x }).reduce((a, b) => a + b) / touches.length,
      y: touches.map(function (v) { return v.y }).reduce((a, b) => a + b) / touches.length
    }
  }

  getDistance (start, end) {
    return {
      x: start.x - end.x,
      y: start.y - end.y
    }
  }

  doubletap ({ data: { event } }) {
    const touch = event.touches[0]
    const pageX = touch.pageX
    const pageY = touch.pageY

    this.zoomHandler.zoom(null, pageX, pageY)
  }

  zoomStart ({ data: { event } }) {
    this.initialCenter = this.getCenter(
      Array.prototype.slice.call(event.touches).map(touch => {
        return { x: touch.pageX, y: touch.pageY }
      })
    )
    this.previousEvent = { touches: [{
      pageX: this.initialCenter.x,
      pageY: this.initialCenter.y
    }] }
  }

  zoom ({ data: { event, scale, touches } }) {
    const rect = this.img.getBoundingClientRect()
    const delta = scale >= this.previousScale ? -1 : 1
    const center = this.getCenter(touches)
    const offsetX = center.x - rect.left - window.pageXOffset
    const offsetY = center.y - rect.top - window.pageYOffset
    const offset = { x: offsetX, y: offsetY }
    const factor = delta !== 1
      ? (scale * scale) / this.previousScale
      : this.previousScale / (scale * scale)
    const step = factor * 0.015

    const distance = this.getDistance(this.initialCenter, center)

    this.previousScale = scale

    event.preventDefault()

    if (Math.abs(distance.x) > 5 || Math.abs(distance.y) > 5) {
      this.dispatchEvent('update', { delta, offset, step })
      this.drag({ data: { event: { touches: [{ pageX: center.x, pageY: center.y }] } } })
    }
  }

  zoomEnd (e) {
    this.previousEvent = null
    this.previousScale = 1
  }

  dragStart ({ data: { event } }) {
    this.previousEvent = event
  }

  drag ({ data: { event } }) {
    if (this.draggable()) {
      this.overlay.hide()

      if (event.cancelable && event.preventDefault) {
        event.preventDefault()
      }

      const touch = event.touches[0]
      const previousTouch = this.previousEvent.touches[0]

      const offset = {
        x: touch.pageX - previousTouch.pageX,
        y: touch.pageY - previousTouch.pageY
      }

      this.previousEvent = event
      this.dispatchEvent('update', { offset })
    } else {
      this.overlay.show()
    }
  }

  dragEnd (e) {
    this.previousEvent = null
  }

  destroy () {
    this.gestures.destory()
  }
}

TouchHandler.handle = (scope) => {
  const touch = new TouchHandler(scope)
  touch.addEventListener('update', ({ data: { delta, offset, step } }) => {
    typeof delta !== 'undefined'
      ? scope.calc(delta, offset.x, offset.y, step)
      : scope.position(offset)
  })

  return touch
}

export default TouchHandler
