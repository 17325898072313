import UI from '../ui'
import EventEmitter from '../utils/event-emitter'

class ZoomHandler extends EventEmitter {
  constructor (scope) {
    super()
    this.el = scope.el
    this.img = scope.img
    this.config = scope.config
    this.markers = scope.markers
    this.animationTimeout = null
    this.overlay = new UI.Overlay(scope)
    this.keyCode = null;

    this.el.addEventListener('wheel', this)
    this.el.addEventListener('dblclick', this)
    document.body.addEventListener('keyup', this)
    document.body.addEventListener('keydown', this)
  }

  handleEvent (e) {
    e.type === 'dblclick'
    switch(e.type) {
      case 'dblclick':
        this.zoom(this.config.zoomStep, e.pageX, e.pageY)
        break;
      case 'wheel':
        this.wheel(e)
        break;
      case 'keyup':
        this.keyCode = null
        break;
      case 'keydown':
        this.keyCode = e.keyCode
        break;
    }
  }

  zoomIn (step) {
    step = step || this.config.zoomStep
    step = step < 0 ? step * -1 : step
    this.zoom(step)
  }

  zoomOut (step) {
    step = step || this.config.zoomStep
    step = step > 0 ? step * -1 : step
    this.zoom(step)
  }

  zoom (step, x, y) {
    step = step || this.config.zoomStep
    const delta = step === 0 ? 0 : (step > 0 ? -1 : 1)
    step = Math.abs(step)

    const pageX = x || 0
    const pageY = y || 0
    const pageXOffset = x ? window.pageXOffset : 0
    const pageYOffset = y ? window.pageYOffset : 0
    const rect = this.img.getBoundingClientRect()
    const offsetX = (pageX === 0) ? (rect.width / 2) : (pageX - rect.left - pageXOffset)
    const offsetY = (pageY === 0) ? (rect.height / 2) : (pageY - rect.top - pageYOffset)
    const offset = { x: offsetX, y: offsetY }

    this.animate()
    this.dispatchEvent('update', { delta, offset, step })
  }

  wheel (event) {
    if (this.keyCode === 17) {
      event.preventDefault()
    }
    if (this.config.lockZoom && [17, 91].indexOf(this.keyCode) === -1) {
      this.overlay.show()
    } else {
      event.preventDefault()
      this.overlay.hide()

      const rect = this.img.getBoundingClientRect()
      const delta = event.deltaY ? event.deltaY : -1 * event.wheelDelta
      const offsetX = event.pageX - rect.left - window.pageXOffset
      const offsetY = event.pageY - rect.top - window.pageYOffset
      const offset = { x: offsetX, y: offsetY }

      this.dispatchEvent('update', { delta, offset, step: 0.05 })
    }
  }

  animate () {
    this.animationTimeout && clearTimeout(this.animationTimeout)
    this.img.style.transition = 'all .2s'
    this.markers.map(m => { m.el.style.transition = 'all .2s' })
    this.animationTimeout = setTimeout(() => {
      this.img.style.transition = ''
      this.markers.map(m => { m.el.style.transition = '' })
    }, 200)
  }

  destroy () {
    this.el.removeEventListener('wheel', this)
    this.el.removeEventListener('dblclick', this)
  }
}

ZoomHandler.handle = (scope) => {
  const zoom = new ZoomHandler(scope)
  zoom.addEventListener('update', ({ data: { delta, offset, step } }) => {
    scope.calc(delta, offset.x, offset.y, step)
  })
  return zoom
}

export default ZoomHandler
