import EventEmitter from '../utils/event-emitter'

class DragHandler extends EventEmitter {
  constructor (scope) {
    super()
    this.el = scope.el
    this.img = scope.img
    this.previousEvent = null
    this.config = scope.config
    this.el.addEventListener('mousedown', this)
  }

  handleEvent (e) {
    this[e.type](e)
  }

  mousedown (e) {
    e.preventDefault()
    this.previousEvent = e
    document.addEventListener('mousemove', this)
    document.addEventListener('mouseup', this)
  }

  mousemove (e) {
    e.preventDefault()

    const offset = {
      x: e.pageX - this.previousEvent.pageX,
      y: e.pageY - this.previousEvent.pageY
    }

    this.previousEvent = e
    this.dispatchEvent('update', { offset })
  }

  mouseup (e) {
    document.removeEventListener('mousemove', this)
    document.removeEventListener('mouseup', this)
    this.previousEvent = null
  }

  destroy () {
    this.el.removeEventListener('mousedown', this)
  }
}

DragHandler.handle = (scope) => {
  const drag = new DragHandler(scope)
  drag.addEventListener('update', ({ data: { offset } }) => {
    scope.position(offset)
  })

  return drag
}

export default DragHandler
