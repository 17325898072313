import ImageZoomMap from './imageZoomMap'
export default class ImageZoom {

    /**
     * Create a new instance.
     *
     * @param {string} article detail path
     */
    constructor() {

      this.init();

    }

    init() {

        $(function() {
          var $images = $('img.image-zoom')
          $images.each(function () {
            var $container = $(this).closest('.col_element')
            var $map = $container.find('map')
            var $markers = $container.find('span.image-map-marker')
            var markers = $markers.map((i, el) => {
              var $el = $(el)
              let index = $el.attr('data-numbering');
              let area = $('area[data-id="'+index+'"]', $map);
              let type = area.attr('shape') === 'rect' ? 'area' : 'point';
              let coords = area.attr('coords').split(',');
              let tooltip = area.data('type') === 'text'
                ? atob(area.attr('text'))
                : null;

              return {
                el,
                type,
                tooltip,
                coordinates: type === 'xxx'
                  ? [{x: coords.slice(0, 2).shift(), y: coords.slice(0, 2).pop()}]
                  : [{x: coords.slice(0, 2).shift(), y: coords.slice(0, 2).pop()}, {x: coords.slice(-2).shift(), y: coords.slice(-2).pop()}]
              }
            }).toArray()

            new ImageZoomMap($(this).get(0), { markers });
          })
        });

    }

  }
