export default class ArticleLinks {

  /**
   * Create a new instance.
   *
   * @param {string} article detail path
   */
  constructor(url) {

    this.init(url || '/article/:id');

  }

  init(url) {
    this.url = url;

    document.querySelectorAll('a[data-type="int-link"').forEach((element) => {
      element.removeEventListener('click', this);
      element.addEventListener('click', this);
    });

  }

  handleEvent(e) {
    if (e.type == 'click') {

      e.preventDefault();

      let anchor = e.currentTarget;
      let articleId = anchor.getAttribute('articleid');
      let target = anchor.getAttribute('target');
      let heading = anchor.getAttribute('heading');

      this.open(articleId, target, heading);

    }
  }

  open(articleId, target, heading) {

    if (articleId.indexOf("#") !== -1) {
      articleId = articleId.substring(articleId.indexOf("#")+1);
    }

    let url = window.location.origin + this.url.replace(':id', articleId);

    if (articleId === ARTICLE_ID && !target) {
      url = "";
    }

    if (heading) {
      url += "#" + heading;
    }

    (target) ?
      window.open(url, target):
      window.location = url;

  }
}
