/**
 * Project Frontend Application scripts
 *
 * @author Matej Svajger <hello@matejsvajger.com>
 */

'use strict';

import xhr          from './xhr';
import Dispatcher   from './dispatcher';

import ArticleLinks      from '../modules/ArticleLinks';
import ArticleElements   from '../modules/ArticleElements';
import ImageSubtitles    from '../modules/ImageSubtitles';
import ImageCopyrights   from '../modules/image-copyrights';
import ImageZoom         from '../modules/ImageZoom';
import ArticleComparison from '../modules/ArticleComparison';
import MessageAuthor     from '../modules/MessageAuthor';
import PublishArticle    from '../modules/PublishArticle';
import LocationsApp      from '../modules/LocationsApp';
import IsScrolling       from '../modules/isScrolling';
import TextareaAutosize  from '../modules/TextareaAutosize';
// import OrderedLists      from '../modules/ordered-lists';
// import Asteroids         from '../modules/asteroids';

require('./forEach').polyfill();

export default class {

  constructor() {

    this.xhr = xhr;
    this.event = new Dispatcher();
    this.articles = new ArticleElements();
    this.articleComparison = new ArticleComparison();

    // new Asteroids();

  }

  init() {

    this.imageCopyrights = new ImageCopyrights();
    this.imageSubtitles = new ImageSubtitles();
    this.articleLinks = new ArticleLinks();
    this.imageZoom = new ImageZoom();
    this.articles.init();
    this.articleComparison.init();
    this.locations = new LocationsApp();

    // new OrderedLists();
    new MessageAuthor();
    new PublishArticle();
    new IsScrolling('body');
    new TextareaAutosize();

    //- Mobile sticky hover hack - remove hover effect on action buttons.
    $('.header__actions__round--action').each(function (i, el) {
      el.ontouchend = el.onclick = function () {
        setTimeout(() => {
          var el = this;
          var par = el.parentNode;
          var next = el.nextSibling;
          par.removeChild(el);
          par.insertBefore(el, next)
        }, 750)
      }
    });

  }

}
