export default class Cooridnates {
  constructor (img, data) {
    this.img = img
    this.data = data || []
  }

  get isSquare () {
    return this.data.length !== 1
  }

  get start () {
    return this.data.slice(0, 1).pop()
  }

  get end () {
    return this.isSquare ? this.data.slice(1, 2).pop() : this.start
  }

  get width () {
    return this.isSquare ? this.end.x - this.start.x : 0
  }

  get height () {
    return this.isSquare ? this.end.y - this.start.y : 0
  }

  get size () {
    return {
      width: (this.width / this.img.naturalWidth) * 100,
      height: (this.height / this.img.naturalHeight) * 100
    }
  }

  get point () {
    const { x, y } = this.start

    return {
      x: (x / this.img.naturalWidth) * 100,
      y: (y / this.img.naturalHeight) * 100
    }
  }

  offset (x, y) {
    return {
      x: (x * 100) / this.img.clientWidth,
      y: (y * 100) / this.img.clientHeight
    }
  }

  pos (w, h) {
    const { x, y } = this.point

    return {
      x: x * (w / this.img.clientWidth),
      y: y * (h / this.img.clientHeight)
    }
  }

  calc (bgWidth, bgHeight, offsetX, offsetY) {
    const { width, height } = this.size
    const pos = this.pos(bgWidth, bgHeight)
    const offset = this.offset(offsetX, offsetY)
    const factor = bgWidth / this.img.clientWidth

    return {
      top: pos.y + offset.y,
      left: pos.x + offset.x,
      width: width * factor,
      height: height * factor
    }
  }
}
