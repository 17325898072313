import ee from './event-emitter'
import coord from './coordinates'

export const srcToBackground = img => {
  const canvas = document.createElement('canvas')
  img.style.backgroundImage = `url("${img.src}")`
  img.style.backgroundRepeat = 'no-repeat'
  canvas.width = img.naturalWidth
  canvas.height = img.naturalHeight
  img.src = canvas.toDataURL()
}

export const createMarkup = html => {
  const div = document.createElement('div')
  div.innerHTML = html
  return div.firstChild
}

export const wrap = (el, wrapper) => {
  if (el.parentElement) {
    el.parentElement.appendChild(wrapper)
    wrapper.appendChild(el)
    return true
  } else {
    return false
  }
}

export function findAncestor (el, cls) {
  while ((el = el.parentElement) && !el.classList.contains(cls));
  return el
}

export const isMac = () => navigator.platform.toUpperCase().indexOf('MAC') >= 0

export const isFunction = obj => typeof obj === 'function' || false

export const EventEmitter = ee

export const Coordinates = coord

export default {
  isMac,
  isFunction,
  Coordinates,
  createMarkup,
  findAncestor,
  EventEmitter,
  srcToBackground
}
