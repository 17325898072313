import { Coordinates, createMarkup } from '../utils'
import Tooltip from './tooltip';

export default class Marker {
  constructor (id, config, container) {
    let { el, type, tooltip, coordinates } = config

    this.id = id
    this.type = type
    this.img = container.img
    this.container = container
    this.coordinates = new Coordinates(this.img, coordinates)
    this.el = typeof el === 'string'
      ? createMarkup(el.replace('{{ id }}', id))
      : el || createMarkup(`<span>${id}</span>`)

    if (tooltip) {
      this.tooltip = new Tooltip(this.el, tooltip, container);
      this.el.addEventListener('mouseenter', this);
      this.el.addEventListener('mouseleave', this);
    }

    this.el.classList.add('imagemap-zoom--marker')
    this.el.classList.add(
      type === 'area'
        ? 'imagemap-zoom--marker-area'
        : 'imagemap-zoom--marker-point'
    )

    container.addChild(this.el)
  }

  update (bgWidth, bgHeight, offsetX, offsetY) {
    const p = this.coordinates.calc(
      bgWidth, bgHeight, offsetX, offsetY
    )

    this.el.style.top = `${p.top}%`
    this.el.style.left = `${p.left}%`

    if (this.type === 'area') {
      this.el.style.width = `${p.width}%`
      this.el.style.height = `${p.height}%`
    }
  }

  destroy () {
    this.container.removeChild(this.el)
    this.el.removeEventListener('mouseenter', this);
    this.el.removeEventListener('mouseleave', this);
  }

  handleEvent (e) {
    switch (e.type) {
      case 'mouseenter':
        this.tooltip.show();
        break;
      case 'mouseleave':
        this.tooltip.hide();
        break;
    }
  }
}
