<template>
    <div :id="'articleElement-' + id">
        <div v-if="article && scriptCounter === scriptsToLoad">
            <div v-html="article.content"></div>
            <article-contact :data="article.contacts"></article-contact>
        </div>
        <div v-if="error" class="alert alert-warning article-not-found hidden">
            <strong><i class="fa fa-exclamation-triangle"></i> Error:</strong> {{ error }}
        </div>
    </div>
</template>

<script>

import ArticleContact from './ArticleContact';
import {loadScript, loadStyle, initArticleScripts} from '../../core/utils';

export default {

    components: {

        'article-contact': ArticleContact
    },

    props: {
        id: {
            default: null
        },
        lang: {
            default: null
        },
        version: {
            default: ""
        }
    },

    data() {
        return {
            scriptCounter: 0,
            scriptsToLoad: 1,
            article: null,
            error: null,
        }
    },
    created() {

        new App.xhr({
            id: this.id,
            lang: this.lang,
            version: this.version
        }).post(App.articles.url)
            .then(a => this.onLoad(a))
            .catch(err => this.onError(err));
    },

    methods: {

        onError(error) {
            this.error = error.default;
            App.event.fire('article.rendered');
        },

        onLoad(article) {

            this.scriptsToLoad = article.scripts.length;
            this.article = article;

            article.styles.map(loadStyle);
            article.scripts.map(this.loadScripts);

            if(this.scriptsToLoad === 0) {
                initArticleScripts(this.id);
                App.event.fire('article.rendered');
            }

        },

        loadScripts(url) {

            loadScript(url).then(() => {

                this.scriptCounter++;

                //- Fire DomReady Event to initialize
                //- Article Scripts loaded in collections via ajax
                if (this.scriptCounter === this.scriptsToLoad) {

                    setTimeout(() => {
                        initArticleScripts(this.id);
                        App.event.fire('article.rendered');
                    }, 1);

                }

            });

        }

    }

}
</script>
