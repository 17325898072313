import { closest, imageLoaded } from "../../core/utils";
export default class ImageCopyrights {

  /**
   * Create a new instance.
   *
   * @param {string} article detail path
   */
  constructor(selector, gallery) {
    this.selector = selector ? selector : 'div[data-updatetype="image"] img, div[data-updatetype="imagemap"] img';
    this.gallery = gallery ? gallery : 'div[data-updatetype="image_gallery"] img.carousel-cell-image';
    this.init()
    this.initGallery();
  }

  init() {
    setTimeout(() => {
      document.querySelectorAll(this.selector).forEach((element) => {
        this.appendCopyright(element);
      });
    }, 100);
  }

  initGallery() {
    setTimeout(() => {
      document.querySelectorAll(this.gallery).forEach((element) => {
        this.appendCopyrightToGallery(element);
      });
    }, 100);
  }

  appendCopyright(element) {
    let copyright = element.dataset.copyright;

    if (copyright && copyright.length) {

      let span = document.createElement('span');
          span.className = "copyright-info"
          span.innerHTML = `<span class="copyright-info__icon">©</span><span class="copyright-info__text">${copyright}</span>`;

      let col = closest('.col_wrapper', element)
      if (col && col.querySelectorAll('[data-updatetype="text"]').length) {
        this.positionCopyrightForTextImageElement(element, span);
      }

      this.insertAfter(element, span);
    }
  }

  appendCopyrightToGallery(element) {
    let copyright = element.dataset.copyright;

    if (copyright && copyright.length) {

      let wrapper = document.createElement('span');
          wrapper.className = 'copyright-wrapper';
      let span = document.createElement('span');
          span.className = "copyright-info"
          span.innerHTML = `<span class="copyright-info__icon">©</span><span class="copyright-info__text">${copyright}</span>`;

      this.insertAfter(element, wrapper);
      wrapper.appendChild(element);

      this.insertAfter(element, span);
    }
  }

  async positionCopyrightForTextImageElement(image, copyright)
  {
    copyright.style.display = 'none';
    await imageLoaded(image.dataset.src);
    image.src = image.dataset.src;
    await new Promise((resolve) => {
      image.complete && resolve()
      image.load = () => resolve()
    });

    setTimeout(() => {
      let position = image.style.float
      let col = closest('.col_element', image)
      let colWidth = col.clientWidth
      let imgWidth = image.clientWidth
      let imgHeight = image.clientHeight
      let colPadding = 15
      let iconHeight = 20

      copyright.style.top = (imgHeight - iconHeight) + 'px'

      switch (position) {
        case 'left':
          copyright.style.right = (colWidth - imgWidth - colPadding + 15) + 'px'
          break;
        case 'right':
          copyright.style.right = (colPadding + 10) + 'px'
          break;
        case 'none':
        default:
          copyright.style.right = (colWidth/2 - imgWidth/2 - colPadding/2 + 20) + 'px'
          break;
      }
      copyright.style.display = 'block';
    }, 100);
  }

  insertAfter(referenceNode, newNode) {
    referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
  }
}
