const config = {
  zoomStep: 0.25,
  maxZoom: 5,
  lockZoom: true,
  initialZoom: 1,
  container: 'imagemap-zoom',
  markers: [],
  controls: {
    enable: true,
    templates: {
      plus: '<i class="imagemap-zoom--control imagemap-zoom--control-plus">+</i>',
      minus: '<i class="imagemap-zoom--control imagemap-zoom--control-minus">-</i>'
    }
  },
  locale: 'de',
  messages: {
    en: {
      scroll: 'Use :key + scroll to zoom the image',
      mobile: 'Use two fingers to zoom and drag with one'
    },
    de: {
      scroll: 'Halte die Taste ":key" beim Scrollen gedrückt, um das Bild zu vergrößern',
      mobile: 'Verwenden Sie zwei Finger zum Zoomen und einen zum Ziehen'
    }
  }
}

export default config
