/**
 * ASPIAG Frontend scripts
 *
 * @author Matej Svajger <hello@matejsvajger.com>
 */

import 'promise-polyfill/src/polyfill';
import 'object-assign-polyfill';
import 'lazyload';
import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";

const SentryInit = function (dsn) {
  Sentry.init({
    dsn: dsn,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

if (SENTRY_DSN && SENTRY_DSN.length) {
  SentryInit(SENTRY_DSN)
}

import Application from './core/app';

require('./vendor/wheelzoom.js');

/** Expose the App to browser window object */
window.App =  new Application();

window.App.init();

// Lazy load initializing
let images = document.querySelectorAll('#newsletterContent div[data-updatetype="image"] img, #newsletterContent div[data-updatetype="image_gallery"] img');
lazyload(images);

// Prevent click on empty image links
let emptyImageLinks = document.querySelectorAll('[data-updatetype="image"] a[href="#"], [data-updatetype="image_gallery"] a[href="#"]');
for (let link of emptyImageLinks) {
  link.addEventListener('click', (e) => {
    e.preventDefault()
  })
}
