import { EventEmitter, createMarkup } from '../utils'

export default class Control extends EventEmitter {
  constructor (type, container) {
    super()
    const { config } = container

    if (Object.keys(config.controls.templates).indexOf(type) === -1) {
      console.error(`Invalid control type: [${type}]`)
    }

    this.type = type
    this.el = createMarkup(config.controls.templates[type])
    this.el.addEventListener('click', this)
    this.el.addEventListener('dblclick', this)
    this.el.addEventListener('touchstart', this)

    container.addChild(this.el)
  }

  handleEvent (e) {
    e.stopPropagation()
    if (e.type === 'click') {
      e.preventDefault()
      this.dispatchEvent(`zoom.${this.type === 'minus' ? 'out' : 'in'}`, this)
    }
  }

  destroy () {
    this.el.removeEventListener('click', this)
    this.el.removeEventListener('dblclick', this)
    this.el.parentElement && this.el.parentElement.removeChild(this.el)
  }
}
