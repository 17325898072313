import Vue   from 'vue';

import Locations from './components/locations/LocationsApp.vue';
const VueGoogleMaps = require('vue2-google-maps');

export default class LocationsApp {

    /**
     * Create a new instance.
     *
     */
    constructor(url) {

      this.components = [];
      this.url = url || window.location.origin + '/locations';
      this.selector = '#locations-content-wrapper';

      Vue.use(VueGoogleMaps, {
        load: {
          key: 'AIzaSyCCCrx6xJ0YnupJyOSMy0l-uTQAOCpjIYg'
        }
      });

      Vue.component('locations-app', Locations);

      this.init();
    }

    init() {

      document.querySelectorAll(this.selector).forEach((element) => {
        this.components.push(
          new Vue({url: this.url, el: element})
        );
      });

    }

  }
