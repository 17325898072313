import Promise from 'promise';
import swal from 'sweetalert2'

export default class MessageAuthor {

    /**
     * Hooks into anchor tags with data-role="message-author"
     * and opens an alert with an option to send a message to author.
     *
     */
    constructor() {
      $(() => {
        this.init();
      });
    }

    init() {
      $('a[data-role="message-author"]').on('click', (e) => this.openModal(e));
    }

    openModal(e) {
      e && e.preventDefault();

      let contact = $(e.currentTarget).data('id');
      let article = $(e.currentTarget).data('article');
      let email = $(e.currentTarget).data('email');

      swal({
        html: 'Kommentar zu "<i>' + document.title + '</i>" an ' + email + '.',
        input: 'textarea',
        showCancelButton: true,
        confirmButtonText: 'Senden',
        cancelButtonText: 'Abbrechen',
        showLoaderOnConfirm: true,
        preConfirm: (message) => {
          return new Promise((resolve, reject) => {

            if (message && message.length) {

              new App.xhr({ id: contact, article: article, message: message})
                .post(window.location.origin + '/author/message')
                .then((resp) => resolve(resp))
                .catch(err => reject(err));

            } else {
              reject('Nachricht darf nicht leer sein.')
            }

          })
        },
        allowOutsideClick: true
      }).then((resp) => {
        swal({type: 'success', title: 'Nachricht gesendet!'})
      });
    }

  }
