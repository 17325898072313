/**
 * Helper to determine if page is scrolled.
 *
 * @author Matej Svajger <hello@matejsvajger.com>
 */

'use strict';


export default class IsScrolling {

    constructor(target, indicator) {

        this.target = target;
        this.indicator = indicator ? indicator : 'is-scrolling';

        $(() => this.init());

    }

    init() {
        this.apply();
        $(window).scroll(() => this.apply());
    }

    apply() {
        ($(window).scrollTop() > 0) ?
            $(this.target).addClass(this.indicator) :
            $(this.target).removeClass(this.indicator);
    }

}
