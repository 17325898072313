import UI from './ui'
import ZoomHandler from './handlers/zoom'
import DragHandler from './handlers/drag'
import TouchHandler from './handlers/touch'
import {
  wrap,
  createMarkup,
  EventEmitter,
  srcToBackground
} from './utils'

// import math from './utils/math'

export default class Container extends EventEmitter {
  constructor (img, config) {
    super()
    const html = `<div class="${config.container}"></div>`
    const el = createMarkup(html)

    el.style.overflow = 'hidden'
    el.style.position = 'relative'
    img.style.position = 'relative'
    img.style.maxWidth = '100%'

    this.el = el
    this.img = img
    this.markers = []
    this.controls = []
    this.config = config
    this.img.src = config.src

    if (wrap(img, el)) {
      this.init()
    } else {
      console.error('Image not mounted in dom.', img)
    }
  }

  init () {
    const { controls, markers, initialZoom } = this.config
    const computedStyle = window.getComputedStyle(this.img, null)
    const width = parseInt(computedStyle.width, 10)
    const height = parseInt(computedStyle.height, 10)
    const bgWidth = width * initialZoom
    const bgHeight = height * initialZoom
    const bgPosX = -(bgWidth - width) / 2
    const bgPosY = -(bgHeight - height) / 2

    this.data = {
      width,
      height,
      bgWidth,
      bgHeight,
      bgPosX,
      bgPosY
    }

    this.zoom = ZoomHandler.handle(this)
    this.drag = DragHandler.handle(this)
    this.touch = TouchHandler.handle(this)

    controls.enable && this.addControls()
    markers.length && this.addMarkers(markers)

    srcToBackground(this.img)
    this.update()

    // this.el.addEventListener('touchstart', this)
    // this.el.addEventListener('touchend', this)
    // this.el.addEventListener('touchcancel', this)
    // this.el.addEventListener('touchmove', this)
  }

  addControls () {
    const zoomIn = new UI.Control('plus', this)
    const zoomOut = new UI.Control('minus', this)

    zoomIn.addEventListener('zoom.in', () => this.zoom.zoomIn())
    zoomOut.addEventListener('zoom.out', () => this.zoom.zoomOut())

    this.controls.push(zoomIn)
    this.controls.push(zoomOut)
  }

  addMarkers (markers) {
    markers.map((marker, index) => {
      this.markers.push(
        new UI.Marker(index, marker, this)
      )
    })
  }

  addChild (el) {
    this.el.appendChild(el)
  }

  removeChild (el) {
    this.el.removeChild(el)
  }

  // handleEvent (e) {
  //   if (e.type === 'touchstart' && e.touches.length === 2) {
  //     e.preventDefault()
  //     this.data.previousDistance = 0
  //     this.data.initDistance = math.hypot(
  //       e.touches[0].pageX - e.touches[1].pageX,
  //       e.touches[0].pageY - e.touches[1].pageY
  //     )
  //   }

  //   if (e.type === 'touchmove' && e.touches.length === 2) {
  //     let distanceX = e.touches[0].pageX - e.touches[1].pageX
  //     let distanceY = e.touches[0].pageY - e.touches[1].pageY
  //     let distance = Math.round(math.hypot(
  //       distanceX,
  //       distanceY
  //     ) - this.data.initDistance)

  //     // let delta = 0
  //     let change = this.data.previousDistance - distance
  //     if (change >= 10) {
  //       // delta = 1
  //     }
  //     if (change <= -10) {
  //       // delta = -1
  //     }

  //     const rect = this.img.getBoundingClientRect()
  //     const pageX = e.touches[0].pageX + distanceX / 2
  //     const pageY = e.touches[0].pageY + distanceY / 2
  //     const offsetX = pageX - rect.left - window.pageXOffset
  //     const offsetY = pageY - rect.top - window.pageYOffset

  //     this.data.previousDistance = distance

  //     this.calc(change, offsetX, offsetY)
  //   }
  // }

  calc (delta, offsetX, offsetY, step) {
    let {
      width,
      height,
      bgWidth,
      bgHeight,
      bgPosX,
      bgPosY
    } = this.data

    const maxZoom = this.config.maxZoom
    const zoomStep = step || this.config.zoomStep

    // Record the offset between the bg edge and cursor:
    const bgCursorX = offsetX - bgPosX
    const bgCursorY = offsetY - bgPosY

    // Use the previous offset to get the percent offset between the bg edge and cursor:
    const bgRatioX = bgCursorX / bgWidth
    const bgRatioY = bgCursorY / bgHeight

    // Update the bg size:
    if (delta < 0) {
      bgWidth += bgWidth * zoomStep
      bgHeight += bgHeight * zoomStep
    } else {
      bgWidth -= bgWidth * zoomStep
      bgHeight -= bgHeight * zoomStep
    }

    // Prevent zooming deeper than max zoom scale
    if (maxZoom) {
      bgWidth = Math.min(width * maxZoom, bgWidth)
      bgHeight = Math.min(height * maxZoom, bgHeight)
    }

    // Take the percent offset and apply it to the new size:
    bgPosX = offsetX - (bgWidth * bgRatioX)
    bgPosY = offsetY - (bgHeight * bgRatioY)

    // Update the data values
    this.data.bgWidth = bgWidth
    this.data.bgHeight = bgHeight
    this.data.bgPosX = bgPosX
    this.data.bgPosY = bgPosY

    // Prevent remdering out of bounds
    this.checkBounds()

    // Prevent zooming out beyond the starting size
    bgWidth <= width || bgHeight <= height
      ? this.reset()
      : this.update()
  }

  position ({ x, y }) {
    this.data.bgPosX += x
    this.data.bgPosY += y
    this.checkBounds()
    this.update()
  }

  update () {
    const { bgWidth, bgHeight, bgPosX, bgPosY } = this.data

    this.markers.map(marker => marker.update(
      bgWidth, bgHeight, bgPosX, bgPosY
    ))

    this.img.style.backgroundSize = `${bgWidth}px ${bgHeight}px`
    this.img.style.backgroundPosition = `${bgPosX}px ${bgPosY}px`
  }

  reset () {
    const { width, height } = this.data
    this.data.bgWidth = width
    this.data.bgHeight = height
    this.data.bgPosX = 0
    this.data.bgPosY = 0
    this.update()
  }

  draggable () {
    const { bgWidth, bgHeight, width, height } = this.data

    const bgDeltaX = width - bgWidth
    const bgDeltaY = height - bgHeight

    return bgDeltaX !== 0 && bgDeltaY !== 0
  }

  checkBounds () {
    const { bgPosX, bgPosY, bgWidth, bgHeight, width, height } = this.data

    const bgDeltaX = width - bgWidth
    const bgDeltaY = height - bgHeight

    this.data.bgPosX = bgPosX < bgDeltaX
      ? bgDeltaX
      : (bgPosX > 0 ? 0 : bgPosX)

    this.data.bgPosY = bgPosY < bgDeltaY
      ? bgDeltaY
      : (bgPosY > 0 ? 0 : bgPosY)
  }

  destroy () {
    this.zoom.destroy()
    this.drag.destroy()
    this.touch.destroy()
    this.markers.map(m => m.destroy())
    this.controls.map(c => c.destroy())
    this.el.parentElement.appendChild(this.img)
    this.el.parentElement.removeChild(this.el)

    const url = /url\("(.*?)"\)/i
    this.img.src = this.img.style.backgroundImage.match(url).pop()
    this.img.removeAttribute('style')
  }
}
