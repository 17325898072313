<template>
    <div id="articleComparison">
        <div v-if="articleComparison && articleComparison.comparisonStatus === comparisonStatusCreated && scriptCounter === scriptsToLoad">
            <div v-html="articleComparison.comparisonContent"></div>
        </div>
        <div class="comparison-in-progress" v-if="articleComparison && articleComparison.comparisonStatus === comparisonStatusGenerating">
            <div class="progress-bar">
                <span v-bind:style="{ width: articleComparison.comparisonProgress * 100 + '%' }"></span>
            </div>
            <div class="progress-information">
                <p class="progress-main-information">Der Versionsvergleich wird generiert. Das kann bis zu 30 Sekunden dauern, bitte haben Sie Geduld.
                Sie können die Seite jederzeit verlassen und später erneut aufrufen.</p>
                <p class="progress-sub-information">Dieser Vergleich muss nur einmal generiert werden und wir bis zur nächsten Änderung gespeichert!</p>
            </div>
        </div>
        <div v-if="error || articleComparison && articleComparison.comparisonStatus === comparisonStatusNeedsGeneration" class="alert alert-warning">
            <strong><i class="fa fa-exclamation-triangle"></i> Error:</strong> {{ error }}
        </div>
    </div>
</template>

<script>

import {loadScript, initArticleScripts} from '../../core/utils';

export default {
    props: {
        id: {
            default: null
        },
        targetversion: {
            default: null
        },
        compareversion: {
            default: null
        }
    },

    data() {
        return {
            articleComparison: null,
            error: null,
            comparisonStatusCreated: COMPARISON_STATUS_CREATED,
            comparisonStatusGenerating: COMPARISON_STATUS_GENERATING,
            comparisonStatusNeedsGeneration: COMPARISON_STATUS_NEEDS_GENERATION,
            comparisonScripts: COMPARISON_SCRIPTS,
            scriptCounter: 0,
            scriptsToLoad: COMPARISON_SCRIPTS.length,
            comparisonRefreshInterval: null,
            comparisonRefreshIntervalMs: 3000
        }
    },
    created() {
        new App.xhr()
            .get(App.articleComparison.url + "/" + this.id + "/compare/" + this.targetversion + "/" + this.compareversion)
            .then(a => this.onLoad(a))
            .catch(err => this.onError(err));
    },

    methods: {
        onError(error) {

        },
        onLoad(comparison) {
            if(this.onComparisonLoaded(comparison)) {
                return;
            }
            let self = this;
            this.comparisonRefreshInterval = setInterval(function() {
                new App.xhr()
                    .get(App.articleComparison.url + "/" + self.id + "/compare/" + self.targetversion + "/" + self.compareversion)
                    .then(comparison => self.onComparisonLoaded(comparison))
                    .catch(err => this.onError(err));
            }, this.comparisonRefreshIntervalMs);
        },
        onComparisonLoaded(comparison) {
            this.articleComparison = comparison;
            console.log('comparison loaded', this.articleComparison)
            if(this.articleComparison.comparisonStatus !== this.comparisonStatusCreated) {
                return false;
            }
            if(this.comparisonRefreshInterval !== null) {
                clearInterval(this.comparisonRefreshInterval);
            }
            this.comparisonScripts.map(this.loadScripts);
            if(this.scriptsToLoad === 0) {
                initArticleScripts();
                App.event.fire('article.rendered');
            }
            return true;
        },

        loadScripts(url) {

            loadScript(url).then(() => {

                this.scriptCounter++;
                //- Fire DomReady Event to initialize
                //- Article Scripts loaded in collections via ajax
                if (this.scriptCounter === this.scriptsToLoad) {

                    setTimeout(() => {
                        initArticleScripts();
                        App.event.fire('article.rendered');
                    }, 1);

                }

            });

        }

    }
};
</script>
