/**
 * Event dispatcher
 *
 * @author Matej Svajger <hello@matejsvajger.com>
 */

export default class Dispatcher {

  /**
   * Create a new Desipatcher instance.
   *
   */
  constructor () {
    this.events = {};
  }

  /**
   * Attach a new event listener to event dispatcher
   * .
   * @param {string} event name
   * @param {function} event handler
   */
  listen(event, callback) {

    // Check if the callback is not a function
    if (typeof callback !== 'function') {
      console.error(`The listener callback must be a function, the given type is ${typeof callback}`);
      return false;
    }


    // Check if the event is not a string
    if (typeof event !== 'string') {
      console.error(`The event name must be a string, the given type is ${typeof event}`);
      return false;
    }

    // Check if this event not exists
    if (this.events[event] === undefined) {
      this.events[event] = {
        listeners: []
      }
    }

    this.events[event].listeners.push(callback);

  }

  /**
   * Remove an event listener from event dispatcher
   * .
   * @param {string} event name
   * @param {function} event handler
   */
  remove(event, callback) {

    // Check if this event not exists
    if (this.events[event] === undefined) {
      console.error(`This event: ${event} does not exist`);
      return false;
    }

    this.events[event].listeners = this.events[event].listeners.filter(listener => {
      return listener.toString() !== callback.toString();
    });

  }

  /**
   * Fire an event
   * .
   * @param {string} event name
   * @param {object | mixed} data passed to event handler
   */
  fire(event, details) {

    // Check if this event not exists
    if (this.events[event] === undefined) {
      console.info(`Event: ${event} does not exist`);
      // no event? do nothing!
      return false;
    }

    this.events[event].listeners.forEach((listener) => {
      listener(details);
    });

  }
}
