import Config from './config'
import Container from './container'

class App {
  constructor (img, config) {
    if (!img || !img.nodeName || img.nodeName !== 'IMG') {
      console.error('[ImageMapZoom] :: Element is not an image:', img)
      return
    }

    this.img = img
    this.config = Object.assign({}, Config, {
      src: img.dataset.src || img.src
    }, config)

    const loader = document.createElement('img')
    loader.addEventListener('load', () => this.load())
    loader.src = this.config.src
  }

  load () {
    this.container = new Container(this.img, this.config)
  }

  destroy () {
    this.container.destroy()
  }
}

export default App
