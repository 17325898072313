<template>
    <div>
        <table-component
            :data="data"
            sort-by="MMType.value"
            sort-order="asc"
            :show-filter="false"
            ref="table"
            @rowClick="notifyClick"
            >
            <table-column show="firm" header-class="name-col" label="Name">
                 <template slot-scope="row">
                    <a :href="'/de/contact/' + row.id" target="_blank">{{ row.firm }}</a>
                </template>
            </table-column>
            <table-column show="country"          header-class="country-col" label="Land"></table-column>
            <table-column show="Marktnr"          header-class="marktnr-col" label="Marktnr."></table-column>
            <table-column show="MMTypValue"       header-class="type-col"    label="MM-Typ"></table-column>
            <table-column show="openDate"         header-class="open-col"    label="Eröffnungsdatum" data-type="date:YYYY-MM-DD"></table-column>
            <table-column show="lastUpdated"      header-class="update-col"  label="Letzter Umbau"></table-column>
            <table-column show="salesArea"        header-class="sales-col"   label="Verkaufsfläche" :formatter="squareArea"></table-column>
            <table-column show="totalArea"        header-class="total-col"   label="Gesamtfläche" :formatter="squareArea"></table-column>
            <!-- <table-column show="Kundenanzahl"     header-class="count-col"   label="Kundenanzahl" :formatter="thousands"></table-column>
            <table-column show="Umsatz netto"     header-class="netto-col"   label="Umsatz" :formatter="currency"></table-column>
            <table-column show="Durchschnittsbon" header-class="average-col" label="Durchschnittsumsatz"></table-column> -->

            <template slot="tfoot">
                <tr v-if="pagination">
                    <th colspan="9">
                        <h2 v-if="loading"><i class="fa fa-spinner fa-spin"></i></h2>
                        <i v-else>Zeige {{ pagination.count >= pagination.numItems ? pagination.numItems : pagination.count }} von {{ pagination.count }} Einträge</i>
                    </th>
                </tr>
            </template>
        </table-component>
    </div>
</template>

<script>
// import VueTable from 'vue-table-component';
import { TableComponent, TableColumn } from 'vue-table-component';

// VueTable.settings({
//     filterPlaceholder: 'Filter Tablle…',
//     filterNoResults: 'Es gibt keine übereinstimmenden Zeilen',
// });

export default {

    props: {
        data: {
            default: null,
            type: [Array, Function]
        },
        pagination: {
            default: null
        },
        loading: {
            default: false,
            type: [Boolean]
        }
    },

    components: {
        TableComponent,
        TableColumn
    },

    methods: {

        currency(value) {
            let number = parseFloat(value)
            number = isNaN(number) ? 0 : number
            // https://stackoverflow.com/a/14428340/2306363
            // number to currency -> formats 123123 to 123,123.00 €
            return number.toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+,)/g, '$1.') + '&nbsp;&euro;'
        },

        thousands(value) {
            let n = 0,      // n: length of decimal
                x = 3,      // x: length of whole part
                c = '',     // c: decimal delimiter
                s = '.',    // s: sections delimiter
                re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
                num = parseFloat(value).toFixed(Math.max(0, ~~n));

            return isNaN(num) ? value : (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
        },

        squareArea (value, rowProperties) {
            return value ? `${this.thousands(value)}m<sup>2</sup>` : '';
        },

        notifyClick (row) {
            this.$emit('rowClick', row.data.id);
        },

        refresh () {
            this.$refs.table.refresh();
        },

        sorting () {
            return this.$refs.table.sort;
        }

    }
}
</script>


<style lang="scss">
.table-component {

    .name-col {
        width: 20%;
    }

    .country-col {
        width: 8%;
    }

    .type-col {
        width: 8%;
    }

    .open-col {
        width: 10%;
    }

    .update-col {
        width: 10%;
    }

    .sales-col {
        width: 10%;
    }

    .count-col {
        width: 10%;
    }

    .netto-col {
        width: 12%;
    }

    .average-col {
        width: 12%;
    }

    .table-component__th--sort-asc,
    .table-component__th--sort-desc {

        position: relative;

        &:before {
            font-family: Font Awesome\ 5 Free;
            font-weight: 900;
            font-size: 15px;

            content: "";
            position: absolute;
            line-height: 40px;
            display: block;
            height: 100%;
            width: 22px;
            right: 0;
            top:0;

            color: white;
        }

    }

    .table-component__th--sort-asc:before {
        content: '\f160';
    }

    .table-component__th--sort-desc:before {
        content: '\f161';
    }

}
</style>
