import Vue   from 'vue';

import ArticleComparisonVue from './components/ArticleComparison.vue';

export default class ArticleComparison {

    /**
     * Create a new instance.
     *
     */
    constructor(url) {

      this.components = [];

      this.url = url || window.location.origin + '/article';
      this.selector = '#article-comparison';
        Vue.component('article-comparison', ArticleComparisonVue);
    }

    init() {
        App.event.listen('article.rendered', () => {
            // Wait for a few MS, because the article dom needs some time to render
            // and the events seem to be firing too early
            setTimeout(() => {
                // populateSidebarNavigation();
                setDefinitionClickEvents();
            }, 150);
        });
      document.querySelectorAll(this.selector).forEach((element) => {
        this.components.push(
          new Vue({url: this.url, el: element})
        );
      });

    }

  }
